import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class ProjectDetailService {
  projectItemData: any
  endpointProjectLogo = `${environment.api}/project/file/`;
  constructor(protected http: HttpClient) { }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.api}/project/create`, data);
  }
  update(project_id: any, data: any): Observable<any> {
    return this.http.put(`${environment.api}/project/update/${project_id}`, data);
  }

  sendDataViewDataItem(followupItemData: any) {
    this.projectItemData = followupItemData
  }
  reciveDataViewDataItem() {

    return this.projectItemData
  }
  getProjectByPermision(filterData: any): Observable<any> {
    return this.http.post(`${environment.api}/project/filter/`, filterData);
  }

  getProject(page: any, filterData: any): Observable<any> {
   
    return this.http.post<any>(`${environment.api}/project/list?page=${page}`, filterData);
  }

uploadImageFileLogo(project_id: string, data: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', data);
    return this.http.put<any>(`${environment.api}/project/file/${project_id}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

  }

}
