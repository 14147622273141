import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectDetailService } from 'src/app/shared/services/project-detail.service';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-request-form-create-guest',
  templateUrl: './request-form-create-guest.component.html',
  styleUrls: ['./request-form-create-guest.component.scss']
})
export class RequestFormCreateGuestComponent {
  dropdownSettingsProject: any 
  projectList:any
 constructor(
    private projectDetailService: ProjectDetailService,
    private projects: ProjectService,
    private router: Router,
   
  ) { }
  ngOnInit() {
    this.getProject_Data()
    this.dropdownSettingsProject = {
      singleSelection: true,
      idField: 'project_id',
      textField: 'project_name_th',
      allowSearchFilter: true,

    };
  }
 getProject_Data(): void {
    this.projects.all().subscribe(
      (res: any) => {
        
        this.projectList = res.data;
       
      }
    );
  }

}
