import { Component } from "@angular/core";
import { Router } from "@angular/router";
import liff from "@line/liff";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/shared/services/auth.service";
import { UserService } from "src/app/shared/services/user.service";
interface getProfile {
  displayName: string;
  userId: string;
  pictureUrl: string | undefined;
}
interface idToken {
  email: string | undefined;
}
@Component({
  selector: "app-line-activated",
  templateUrl: "./line-activated.component.html",
  styleUrls: ["./line-activated.component.scss"],
})
export class LineActivatedComponent {
  getProfile: getProfile = {
    displayName: "",
    userId: "",
    pictureUrl: "",
  };
  checkOs: any;
  idToken: any;
  qrcodeValue: any;
  lineUserId: any;
  userData: any;
  check: any;
  constructor(private userService: UserService, private router: Router, private toastr: ToastrService, private modalService: NgbModal, private auth: AuthService) {}

  async ngOnInit() {
    await liff
      .init({
        liffId: '2003840370-rEPGvpmK', // Use own liffId
        withLoginOnExternalBrowser: true, // Enable automatic login process
      })
      .catch((err) => {
        throw err;
      });

    if (liff.isLoggedIn()) {
      this.checkOs = liff.getOS();
      console.log(this.checkOs);

      if (this.checkOs === 'web') {
        // this.router.navigate(['/error']);
      }
      let getProfile = await liff.getProfile();
      let idToken = liff.getDecodedIDToken();

      this.getProfile.displayName = getProfile.displayName;
      this.getProfile.userId = getProfile.userId;
      this.getProfile.pictureUrl = getProfile.pictureUrl;
      this.idToken.email = idToken?.email;
      console.log(idToken?.email);
      console.log(this.getProfile.pictureUrl);
     
    } else {
      liff.login();
    }
    this.signin();
  }
  async getDataLogin() {
    await liff
      .init({
        liffId: "2003840370-rEPGvpmK", // Use own liffId
        withLoginOnExternalBrowser: true, // Enable automatic login process
      })
      .catch((err) => {
        throw err;
      });

    if (liff.isLoggedIn()) {
      this.checkOs = liff.getOS();

      if (this.checkOs === "web") {
        // this.router.navigate(['/error']);
      }
      let getProfile = await liff.getProfile();
      let idToken = liff.getDecodedIDToken();

      this.lineUserId = getProfile?.userId;
    } else {
      liff.login();
      if (liff.isLoggedIn()) {
        this.checkOs = liff.getOS();

        if (this.checkOs === "web") {
          // this.router.navigate(['/error']);
        }
        let getProfile = await liff.getProfile();
        let idToken = liff.getDecodedIDToken();

        this.lineUserId = getProfile?.userId;
      }
    }
  }
  signin() {
    const login = {
      username: "thavorn_pr",
      password: "123456",
    };
    this.auth.login(login).subscribe({
      next: (login) => {
        /*  localStorage.setItem("user_id", JSON.stringify(login.user.user_id));
                     this.tokenStorage.setItem('jwt',login.jwt); */
        return login.status;
      },
      error: (e) => {
        console.log(e.error.message);
      },
      complete: () => {},
    });
  }
  async scanqr() {
    this.qrcodeValue = null;
    try {
      const result = await liff.scanCodeV2();
      this.qrcodeValue = result.value;

      if (this.qrcodeValue) {
        this.userService.get(this.qrcodeValue).subscribe((res: any) => {
          this.userData = res;
        });
      }
    } catch (error) {
      console.log("scanCodeV2", error);
    }
  }

  activateLineUser() {
    const data = {
      line_user_id: this.lineUserId,
    };
    if (this.qrcodeValue && this.lineUserId) {
      this.userService.updateActivateLine(this.qrcodeValue, data).subscribe({
        next: (user) => {
          this.toastr.success("เชื่อมต่อกับ Line เสร็จสิ้น", "แจ้งเตือน", { timeOut: 1000, closeButton: true, progressBar: true });
          return user;
        },
        error: (e) => {
          console.log(e);
          this.toastr.error(e.error.message, "แจ้งเตือน", { timeOut: 3000, closeButton: true, progressBar: true });
        },
        complete: () => {
          if (this.qrcodeValue) {
            this.userService.get(this.qrcodeValue).subscribe((res: any) => {
              this.userData = res;
            });
          }
        },
      });
    }
  }
}
