import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class JobTypeService extends RestService {
  endpoint = `${environment.api}/job-type`;

   getData(filterData: any,page: any): Observable<any> {
    console.log(filterData)
    return this.http.post<any>(`${environment.api}/job-type/list?page=${page}`, filterData);
  }
}
