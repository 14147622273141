import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComparisonService extends RestService {
  endpoint = `${environment.api}/comparison`;
  comparisonItemData: any
  comparisonViewItem: any

  sendDataViewDataItem(comparisonItemData: any) {
    this.comparisonItemData = comparisonItemData
  }
  reciveDataViewDataItem() {

    return this.comparisonItemData
  }


  sendDataViewItem(comparisonViewItem: any) {
    this.comparisonViewItem = comparisonViewItem
  }
  reciveDataViewItem() {

    return this.comparisonViewItem
  }

  createComparison(data: any): Observable<any> {
    return this.http.post(`${environment.api}/comparison/create`, data);
  }
  editComparison(comparison_id: any, data: any): Observable<any> {
    return this.http.put(`${environment.api}/comparison/edit/${comparison_id}`, data);
  }

  getData(page: any, filterData: any): Observable<any> {
    return this.http.post<any>(`${environment.api}/comparison/list?page=${page}`, filterData);
  }

   getDataByID(comparison_id: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/comparison/${comparison_id}`);
  }

  ApprovedRequest(comparison_id: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/comparison/approved_request/${comparison_id}`, data);
  }
  getDataSetting(page: any, filterData: any): Observable<any> {
    return this.http.post<any>(`${environment.api}/comparison-setting/list?page=${page}`, filterData);
  }

  ApprovedฺByBm(comparison_id: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/comparison/approved_by_bm/${comparison_id}`, data);
  }
  ApprovedฺByEm(comparison_id: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/comparison/approved_by_en/${comparison_id}`, data);
  }

  ApprovedฺByCentral_Engineering(comparison_id: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/comparison/approved_by_central_engineering/${comparison_id}`, data);
  }

   ApprovedฺByCentral_OM(comparison_id: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/comparison/approved_by_om/${comparison_id}`, data);
  }
 ApprovedฺByPurchase(comparison_id: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/comparison/approved_by_purchase/${comparison_id}`, data);
  }
   notApproved(comparison_id: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/comparison/not_approved/${comparison_id}`, data);
  }

  ApprovedฺByManager(comparison_id: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/comparison/approved_by_manager/${comparison_id}`, data);
  }
 NotApprovedฺByManager(comparison_id: any, data: any): Observable<any> {
    return this.http.put<any>(`${environment.api}/comparison/not_approved_by_manager/${comparison_id}`, data);
  }


}
