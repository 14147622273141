import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComparisonVenderItemService extends RestService {
  endpoint = `${environment.api}/comparison-vender-item`;
  comparisonItemData: any
  endpointAttachedFile = `${environment.api}/comparison-vender-attached-file/file/`;
  endpointProjectImage = `${environment.api}/project/file/`;
 
  
 createComparisonVenderItem(comparison_vender_id:any ,data: any): Observable<any> {
    return this.http.post(`${environment.api}/comparison-vender-item/create/${comparison_vender_id}`, data);
  }
  
 editComparisonVenderItem(comparison_vender_item_id:any ,data: any): Observable<any> {
    return this.http.put(`${environment.api}/comparison-vender-item/edit/${comparison_vender_item_id}`, data);
  }
 uploadComparisonVenderAttacheFile(comparison_vender_id: string, data: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', data);
    return this.http.post<any>(`${environment.api}/comparison-vender-attached-file/file/${comparison_vender_id}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

  }

}
