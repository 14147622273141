import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Auth } from "src/app/classes/auth";
import { AuthService } from "src/app/shared/services/auth.service";
import { BusinessTypeService } from "src/app/shared/services/business-type.service";
import { ComparisonMasterTemplateService } from "src/app/shared/services/comparison-master-template.service";
import { ComparisonVenderItemService } from "src/app/shared/services/comparison-vender-item.service";
import { ComparisonVenderService } from "src/app/shared/services/comparison-vender.service";
import { ComparisonService } from "src/app/shared/services/comparison.service";
import { JobTypeService } from "src/app/shared/services/job-type.service";
import { ProjectService } from "src/app/shared/services/project.service";
import { VenderListService } from "src/app/shared/services/veder.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-comparison-view-outsite",
  templateUrl: "./comparison-view-outsite.component.html",
  styleUrl: "./comparison-view-outsite.component.scss",
})
export class ComparisonViewOutsiteComponent {
  page: any = 1;
  user: any;
  followupItemDataForm: FormGroup;
  endpointAttachedFile: any;
  viewMode = "tab1";
  checkPage: boolean;
  comparisonViewItem: any;
  comparisonItemDataPage1: any;
  comparisonItemDataPage2: any;
  comparisonSetting: any;
  approved_setting_manager: any;
  approved_setting_bm: any;
  approved_setting_chief_engineer: any;
  approved_setting_central_engineering: any;
  approved_setting_om: any;
  clickSubmit = false;
  comparisonViewItemRecive!: any;
  endpointProjectImage: any;
  comparison_id: any;
  constructor(private toastr: ToastrService, private formBuilder: FormBuilder, private formBuilderVender1: FormBuilder, private formBuilderVender3: FormBuilder, private formBuilderVender2: FormBuilder, private projects: ProjectService, private authService: AuthService, private router: Router, private modalService: NgbModal, private businessTypeService: BusinessTypeService, private venderListService: VenderListService, private jobTypeService: JobTypeService, private comparisonService: ComparisonService, private comparisonVenderService: ComparisonVenderService, private comparisonVenderItemService: ComparisonVenderItemService, private comparisonMasterTemplateService: ComparisonMasterTemplateService, private route: ActivatedRoute) {}
  ngOnInit() {
    this.comparison_id = this.route.snapshot.paramMap.get("comparison_id");

    this.endpointAttachedFile = this.comparisonVenderItemService.endpointAttachedFile;
    this.endpointProjectImage = this.comparisonVenderItemService.endpointProjectImage;
    const user = Auth.user;
    this.user = user;
    Auth.userEmitter.subscribe((res: any) => {
      this.authService.user;
      this.user = res;
    });

    this.followupItemDataForm = this.formBuilder.group({
      remark: "",
    });
    if (!this.comparison_id) {
      this.router.navigate(["/comparison"]);
    }

    this.getRoleData();
  }
  print() {
    if (window) {
      window.print();
    }
  }

  getRoleData(): void {
    this.comparisonService.getDataByID(this.comparison_id).subscribe((res: any) => {
      this.comparisonViewItem = res.data[0];

      var page1 = [];
      var page2 = [];
      const vender = this.comparisonViewItem?.comparisonVender.sort((a, b) => (a.vender_number > b.vender_number ? 1 : -1));
      for (var i = 0; i < vender?.length; i++) {
        if (i <= 2) {
          page1.push(vender[i]);
        }
        if (i > 2) {
          page2.push(vender[i]);
        }
      }

      this.comparisonItemDataPage1 = page1;
      this.comparisonItemDataPage2 = page2;
      if (page2?.length > 0) {
        this.checkPage = true;
      }
      const filterDataSend = {
        project: this.comparisonViewItem?.project?.project_id,
        textfilter: "",
      };
    });
  }

  openModalConfirmApprovedManager(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true, backdrop: "static" })

      .result.then(
        (result) => {
          if (result === "Ok") {
            const dataSend = {
              project: this.comparisonViewItem?.project?.project_id,
            };

            this.comparisonService.ApprovedฺByManager(this.comparisonViewItem.comparison_id, dataSend).subscribe({
              next: (beers) => {
                this.toastr.success("คุณได้อนุมัติรายการ เสร็จสิ้น", "แจ้งเตือน", { timeOut: 5000, closeButton: true, progressBar: true });
                return beers;
              },
              error: (e) => {
                console.log(e.error);
                this.toastr.error(e.error, "แจ้งเตือน", { timeOut: 5000, closeButton: true, progressBar: true });
              },
              complete: () => {
                window.location.reload();
              },
            });
          }
        },
        (reason) => {
          console.log(reason);
        }
      );
  }

  openStatus(content: any) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", backdrop: "static", size: "lg" });
  }
  openModalNotApproved(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true, backdrop: "static" })

      .result.then(
        (result) => {
          if (result === "Ok") {
            const dataSend = {
              project: this.comparisonViewItem?.project?.project_id,
              remark: this.followupItemDataForm.value.remark,
            };

            this.comparisonService.NotApprovedฺByManager(this.comparisonViewItem.comparison_id, dataSend).subscribe({
              next: (beers) => {
                this.toastr.success("ไม่อนุมัติรายการ เสร็จสิ้น", "แจ้งเตือน", { timeOut: 4000, closeButton: true, progressBar: true });
                return beers;
              },
              error: (e) => {
                console.log(e.error.message);
                this.toastr.error(e.error.message, "แจ้งเตือน", { timeOut: 4000, closeButton: true, progressBar: true });
              },
              complete: () => {
                setTimeout(() => {
                 window.location.reload(); 
                }, 4000);
                
              },
            });
          }
        },
        (reason) => {
          console.log(reason);
        }
      );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.comparisonViewItem?.comparisonVender, event.previousIndex, event.currentIndex);
  }

  openModalChangeNo(content: any) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title", centered: true, backdrop: "static" });
  }

  changVenderNumber() {
    this.comparisonViewItem?.comparisonVender.forEach((element, index) => {
      const data = { vender_number: index + 1 };
      this.comparisonVenderService.editComparisonVenderNumber(element.comparison_vender_id, data).subscribe((res: any) => {});
    });
    this.toastr.success("อัพเดตเสร็จสิ้น", "แจ้งเตือน", { timeOut: 1000, closeButton: true, progressBar: true });
    this.getRoleData();
  }
  change() {
    this.comparisonViewItem.comparisonVender = this.comparisonViewItem?.comparisonVender.sort((a, b) => a.grand_total - b.grand_total);
  }
}
