import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComparisonMasterTemplateService extends RestService {
  endpoint = `${environment.api}/comparison-master-template`;
  comparisonItemData: any

 
  
 createComparisonMasterTemplate(comparison_id:any ,data: any): Observable<any> {
    return this.http.post(`${environment.api}/comparison-master-template/create/${comparison_id}`, data);
  }

 editComparisonMasterTemplate(comparison_master_template_id:any ,data: any): Observable<any> {
    return this.http.post(`${environment.api}/comparison-master-template/edit/${comparison_master_template_id}`, data);
  }


}
