<div style="zoom: 0.9">
  <div class="separator-breadcrumb border-top"></div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div style="margin-top: -280px" *ngIf="comparisonViewItem?.comparisonVender.length < 1" class="loadscreen">
          <h3>กรุณารอสักครู่ กำลังโหลดข้อมูล!</h3>
          <div class="spinner-bubble spinner-bubble-primary d-block"></div>
        </div>
        <div class="card-body">
          <div class="d-flex mb-2">
            <span class="m-auto"></span>


            <!-- ผู้จัดการนิติ -->
            <btn-loading *ngIf="comparisonViewItem?.comparison_status === 'APPROVED' && comparisonViewItem?.approved_setting_manager === true && comparisonViewItem?.approved_by_purchase_status === true"  (click)="openModalConfirmApprovedManager(modalConfirmApproved)" btnClass="btn-success">อนุมัติรายการ</btn-loading> &nbsp;
            <btn-loading *ngIf="comparisonViewItem?.comparison_status === 'APPROVED' && comparisonViewItem?.approved_setting_manager === true && comparisonViewItem?.approved_by_purchase_status === true" (click)="openModalNotApproved(modalConfirmNotApproved)" btnClass="btn-danger">ไม่อนุมัติรายการ</btn-loading>
 &nbsp;
            <btn-loading (click)="openStatus(modalStatus)" btnClass="btn-info">สถานะรายการ</btn-loading>
          </div>
          <div *ngIf="checkPage" style="margin-top: -40px" class="tab-slider--nav">
            <ul class="tab-slider--tabs">
              <li class="tab-slider--trigger" [class.active]="viewMode == 'tab1'" rel="tab1" (click)="viewMode = 'tab1'">หน้าที่ 1</li>
              <li class="tab-slider--trigger" [class.active]="viewMode == 'tab2'" rel="tab2" (click)="viewMode = 'tab2'">หน้าที่ 2</li>
            </ul>
          </div>
          <br /><br />
          <div class="tab-slider--container" [ngSwitch]="viewMode">
            <div id="tab1" class="tab-slider--body" *ngSwitchCase="'tab1'">
              <div class="main">
                <div class="print-area" id="print-area">
                  <div class="row">
                    <div class="col-md-4" *ngIf="comparisonViewItem?.project?.project_logo_image">
                      <img style="margin-top: -30px; margin-left: 10px" [src]="endpointProjectImage + comparisonViewItem?.project?.project_logo_image" width="150px" />
                    </div>
                    <div class="col-md-4">
                      <h4 class="font-weight-bold text-center">
                        {{ comparisonViewItem?.project?.project_name_th }}
                      </h4>
                      <h5 class="font-weight-bold text-center mt-2">ตารางเปรียบเทียบราคา / Price Comparison of : {{ comparisonViewItem?.job_name }}</h5>
                    </div>
                    <div class="col-md-4"></div>

                    <div class="col-md-4">
                      <h6 class="font-weight-bold">รายละเอียด / Detail</h6>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <td class="text-left">บริษัทฯ / Supplier / Vendor</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.company_name }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.company_name }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.company_name }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">ชื่อผู้ติดต่อ / Contact Person</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.venderContactPersons }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.venderContactPersons }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.venderContactPersons }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">ที่อยู่ / Address</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.address }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.address }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.address }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">โทรศัพท์ / Telephone</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.telephone }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.telephone }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.telephone }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">รายละเอียดเพิ่มเติม / E-mail</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.email }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.email }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.email }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">เลขที่ผู้เสียภาษี</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.tax_id_number }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.tax_id_number }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.tax_id_number }}</span>
                            </td>
                          </tr>

                          <tr>
                            <th width="290px" class="p-0" scope="col">
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th class="fw-normal text-center" scope="col">
                                      ลำดับ
                                      <br />Item
                                    </th>
                                    <th style="width: 60%" class="fw-normal text-center" scope="col">รายการที่ต้องการ <br />Description</th>
                                    <th class="fw-normal text-center" scope="col">
                                      จำนวน
                                      <br />Quantity
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let masterTemplate of comparisonViewItem?.comparisonMasterTemplate | orderBySortNumberPage : 'number'; let i = index">
                                    <td class="fw-normal text-l" scope="row">
                                      {{ masterTemplate.number }}
                                    </td>
                                    <td class="fw-normal text-left">
                                      {{ masterTemplate.job_type_template_name }}
                                    </td>
                                    <td class="fw-normal text-center">
                                      {{ masterTemplate.quantity }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </th>
                            <th width="500px" class="p-0" scope="col">
                              <table *ngIf="comparisonItemDataPage1[0]" class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th style="width: 38%" class="fw-normal text-center" scope="col">
                                      สินค้าที่เสนอ
                                      <br />Products offered
                                    </th>
                                    <th style="width: 10%" class="fw-normal text-center" scope="col">
                                      จำนวน
                                      <br />Quantity
                                    </th>
                                    <th style="width: 18%" class="fw-normal text-center" scope="col">
                                      ราคา/หน่วย
                                      <br />Unit Price
                                    </th>
                                    <th tyle="width: 14%;" class="fw-normal text-center" scope="col">
                                      ส่วนลด
                                      <br />Discount
                                    </th>
                                    <th style="width: 20%" class="fw-normal text-center" scope="col">
                                      ราคารวม
                                      <br />Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let vender1 of comparisonItemDataPage1[0]?.comparisonVenderItem | orderBySortNumberPage : 'number'; let i = index">
                                    <td class="fw-normal text-left">
                                      {{ vender1.job_type_template_name }}
                                    </td>
                                    <td class="fw-normal text-center">
                                      {{ vender1.quantity }}
                                    </td>
                                    <td class="fw-normal text-center" scope="row">
                                      {{ vender1.unit_price }}
                                    </td>
                                    <td class="fw-normal text-center" scope="row">
                                      {{ vender1.discount }}
                                    </td>
                                    <td class="fw-normal text-center" scope="row">
                                      {{ vender1.amount }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </th>
                            <th width="500px" class="p-0" scope="col">
                              <table *ngIf="comparisonItemDataPage1[1]" class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th style="width: 38%" class="fw-normal text-center" scope="col">
                                      สินค้าที่เสนอ
                                      <br />Products offered
                                    </th>
                                    <th style="width: 10%" class="fw-normal text-center" scope="col">
                                      จำนวน
                                      <br />Quantity
                                    </th>
                                    <th style="width: 18%" class="fw-normal text-center" scope="col">
                                      ราคา/หน่วย
                                      <br />Unit Price
                                    </th>
                                    <th tyle="width: 14%;" class="fw-normal text-center" scope="col">
                                      ส่วนลด
                                      <br />Discount
                                    </th>
                                    <th style="width: 20%" class="fw-normal text-center" scope="col">
                                      ราคารวม
                                      <br />Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let vender2 of comparisonItemDataPage1[1]?.comparisonVenderItem | orderBySortNumberPage : 'number'; let i = index">
                                    <td class="fw-normal text-left">
                                      {{ vender2.job_type_template_name }}
                                    </td>
                                    <td class="fw-normal text-center">
                                      {{ vender2.quantity }}
                                    </td>
                                    <td class="fw-normal text-center" scope="row">
                                      {{ vender2.unit_price }}
                                    </td>
                                    <td class="fw-normal text-center" scope="row">
                                      {{ vender2.discount }}
                                    </td>
                                    <td class="fw-normal text-center" scope="row">
                                      {{ vender2.amount }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </th>
                            <th width="500px" class="p-0" scope="col">
                              <table *ngIf="comparisonItemDataPage1[2]" class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th style="width: 38%" class="fw-normal text-center" scope="col">
                                      สินค้าที่เสนอ
                                      <br />Products offered
                                    </th>
                                    <th style="width: 10%" class="fw-normal text-center" scope="col">
                                      จำนวน
                                      <br />Quantity
                                    </th>
                                    <th style="width: 18%" class="fw-normal text-center" scope="col">
                                      ราคา/หน่วย
                                      <br />Unit Price
                                    </th>
                                    <th tyle="width: 14%;" class="fw-normal text-center" scope="col">
                                      ส่วนลด
                                      <br />Discount
                                    </th>
                                    <th style="width: 20%" class="fw-normal text-center" scope="col">
                                      ราคารวม
                                      <br />Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let vender3 of comparisonItemDataPage1[2]?.comparisonVenderItem | orderBySortNumberPage : 'number'; let i = index">
                                    <td class="fw-normal text-left">
                                      {{ vender3.job_type_template_name }}
                                    </td>
                                    <td class="fw-normal text-center">
                                      {{ vender3.quantity }}
                                    </td>
                                    <td class="fw-normal text-center" scope="row">
                                      {{ vender3.unit_price }}
                                    </td>
                                    <td class="fw-normal text-center" scope="row">
                                      {{ vender3.discount }}
                                    </td>
                                    <td class="fw-normal text-center" scope="row">
                                      {{ vender3.amount }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </th>
                          </tr>
                          <tr>
                            <td class="text-center">รวมเงิน / Total</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.total | number : "1.2-2" }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.total | number : "1.2-2" }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.total | number : "1.2-2" }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">ส่วนลด / Discount</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.discount | number : "1.2-2" }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.discount | number : "1.2-2" }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.discount | number : "1.2-2" }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">คงเหลือ / Total</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.total_afterdiscount | number : "1.2-2" }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.total_afterdiscount | number : "1.2-2" }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.total_afterdiscount | number : "1.2-2" }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">ภาษีมูลค่าเพิ่ม / VAT 7%</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.vat | number : "1.2-2" }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.vat | number : "1.2-2" }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.vat | number : "1.2-2" }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">รวมเป็นเงิน/ Grand Total</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.grand_total | number : "1.2-2" }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.grand_total | number : "1.2-2" }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.grand_total | number : "1.2-2" }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">หมายเหตุ</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.remark }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.remark }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.remark }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">เงื่อนไขการชำระเงิน/ Term of Payment</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.term_of_payment }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.term_of_payment }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.term_of_payment }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">การรับประกัน / The werranty</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.warranty }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.warranty }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.warranty }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">ใบเสนอราคายืนราคาถึง</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.quotation_stand }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.quotation_stand }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.quotation_stand }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">ผลงานที่ผ่านมา</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.past_work }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.past_work }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.past_work }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-left">เงื่อนไขอื่นๆ / Other conditions</td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[0]">{{ comparisonItemDataPage1[0]?.other_condition }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[1]">{{ comparisonItemDataPage1[1]?.other_condition }}</span>
                            </td>
                            <td class="text-center">
                              <span *ngIf="comparisonItemDataPage1[2]">{{ comparisonItemDataPage1[2]?.other_condition }}</span>
                            </td>
                          </tr>
                          <tr class="notPrint">
                            <td class="text-left">ใบเสนอราคา / Quotation</td>
                            <td class="text-left">
                              <div *ngIf="comparisonItemDataPage1[0]" class="row">
                                <div class="col-md-10">
                                  <a *ngFor="let file of comparisonItemDataPage1[0].comparisonVenderAttachedFile; let i = index" class="mb-1 col-lg-2" [href]="endpointAttachedFile + file.attached_file_name" target="_blank">
                                    <div>
                                      <img alt="" *ngIf="file.attached_file_type === '.xlsx' || file.attached_file_type === '.xls'" src="/assets/icons/xls.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.pdf'" src="/assets/icons/pdf.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.tyattached_file_typepe === '.doc'" src="/assets/icons/doc.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.jpg'" src="/assets/icons/jpg.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.png'" src="/assets/icons/png.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.ppt' || file.type === '.pptx'" src="/assets/icons/ppt.png" width="10%" class="img-fluid" />&nbsp; &nbsp;
                                      <span> ชื่อไฟล์ : {{ file?.originalname + file.attached_file_type }}</span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td class="text-left">
                              <div *ngIf="comparisonItemDataPage1[1]" class="row">
                                <div class="col-md-10">
                                  <a *ngFor="let file of comparisonItemDataPage1[1].comparisonVenderAttachedFile; let i = index" class="mb-1 col-lg-2" [href]="endpointAttachedFile + file.attached_file_name" target="_blank">
                                    <div>
                                      <img alt="" *ngIf="file.attached_file_type === '.xlsx' || file.attached_file_type === '.xls'" src="/assets/icons/xls.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.pdf'" src="/assets/icons/pdf.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.tyattached_file_typepe === '.doc'" src="/assets/icons/doc.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.jpg'" src="/assets/icons/jpg.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.png'" src="/assets/icons/png.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.ppt' || file.type === '.pptx'" src="/assets/icons/ppt.png" width="10%" class="img-fluid" />&nbsp; &nbsp;
                                      <span> ชื่อไฟล์ : {{ file?.originalname + file.attached_file_type }}</span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td class="text-left">
                              <div *ngIf="comparisonItemDataPage1[2]" class="row">
                                <div class="col-md-10">
                                  <a *ngFor="let file of comparisonItemDataPage1[2].comparisonVenderAttachedFile; let i = index" class="mb-1 col-lg-2" [href]="endpointAttachedFile + file.attached_file_name" target="_blank">
                                    <div>
                                      <img alt="" *ngIf="file.attached_file_type === '.xlsx' || file.attached_file_type === '.xls'" src="/assets/icons/xls.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.pdf'" src="/assets/icons/pdf.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.tyattached_file_typepe === '.doc'" src="/assets/icons/doc.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.jpg'" src="/assets/icons/jpg.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.png'" src="/assets/icons/png.png" width="10%" class="img-fluid" />
                                      <img alt="" *ngIf="file.attached_file_type === '.ppt' || file.type === '.pptx'" src="/assets/icons/ppt.png" width="10%" class="img-fluid" />&nbsp; &nbsp;
                                      <span> ชื่อไฟล์ : {{ file?.originalname + file.attached_file_type }}</span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <h6>
                        <span>หมายเหตุ : แนะนำให้เลือก : </span> <span> {{ comparisonViewItem?.recommended }}</span
                        >&nbsp; &nbsp;&nbsp; &nbsp; เนื่องจาก :
                        <span> {{ comparisonViewItem?.reason }}</span>
                      </h6>
                      <h6 style="margin-left: 40px">* รายละเอียดดังกล่าวให้คณะกรรมการพิจารณาอีกครั้ง</h6>
                      <br />
                      <div>
                        <table class="table table">
                          <tbody>
                            <tr>
                              <div class="row d-flex justify-content-end">
                                <div class="col-lg-2">
                                  <br />
                                  <td *ngIf="comparisonViewItem?.approved_by_bm_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                    <br />
                                    ลงชื่อ
                                    {{ comparisonViewItem?.approved_by_bm?.first_name_last_name }}
                                    <br /><br />ผู้จัดทำ / ผู้นำเสนอ <br /><br />วันที่
                                    {{ comparisonViewItem?.approved_by_bm_date | thaidate : "medium" }}

                                    <br /><br />
                                  </td>
                                  <td *ngIf="comparisonViewItem?.approved_by_bm_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                    <br />
                                    ลงชื่อ .....................................................
                                    <br /><br />ผู้จัดทำ / ผู้นำเสนอ <br /><br />วันที่ ..................................................... <br /><br />
                                  </td>
                                  <br />
                                </div>
                                <div *ngIf="comparisonViewItem?.verification_by_engineer" class="col-lg-2">
                                  <br />
                                  <td *ngIf="comparisonViewItem?.approved_by_chief_engineer_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                    <br />
                                    ลงชื่อ
                                    {{ comparisonViewItem?.approved_by_chief_engineer?.first_name_last_name }}
                                    <br /><br />หัวหน้าช่าง <br /><br />วันที่
                                    {{ comparisonViewItem?.approved_by_bm_date | thaidate : "medium" }}
                                    <br /><br />
                                  </td>
                                  <td *ngIf="comparisonViewItem?.approved_by_chief_engineer_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                    <br />
                                    ลงชื่อ .....................................................
                                    <br /><br />หัวหน้าช่าง <br /><br />วันที่ ..................................................... <br /><br />
                                  </td>
                                  <br />
                                </div>
                                <div *ngIf="comparisonViewItem?.verification_by_engineer" class="col-lg-2">
                                  <br />
                                  <td *ngIf="comparisonViewItem?.approved_by_central_engineering_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                    <br />
                                    ลงชื่อ
                                    {{ comparisonViewItem?.approved_by_central_engineering?.first_name_last_name }}
                                    <br /><br />วิศวกรรมส่วนกลาง <br /><br />วันที่
                                    {{ comparisonViewItem?.approved_by_central_engineering_date | thaidate : "medium" }}
                                    <br /><br />
                                  </td>
                                  <td *ngIf="comparisonViewItem?.approved_by_central_engineering_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                    <br />
                                    ลงชื่อ .....................................................
                                    <br /><br />วิศวกรรมส่วนกลาง <br /><br />วันที่ ..................................................... <br /><br />
                                  </td>
                                  <br />
                                </div>

                                <div class="col-lg-2">
                                  <br />
                                  <td *ngIf="comparisonViewItem?.approved_by_om_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                    <br />
                                    ลงชื่อ
                                    {{ comparisonViewItem?.approved_by_om.first_name_last_name }}
                                    <br /><br />ผู้จัดการฝ่ายปฏิบัติการ <br /><br />วันที่
                                    {{ comparisonViewItem?.approved_by_om_date | thaidate : "medium" }}
                                    <br /><br />
                                  </td>
                                  <td *ngIf="comparisonViewItem?.approved_by_om_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                    <br />
                                    ลงชื่อ .....................................................
                                    <br /><br />ผู้จัดการฝ่ายปฏิบัติการ <br /><br />วันที่ ..................................................... <br /><br />
                                  </td>
                                  <br />
                                </div>
                                <div class="col-lg-2">
                                  <br />
                                  <td *ngIf="comparisonViewItem?.approved_by_purchase_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                    <br />
                                    ลงชื่อ
                                    {{ comparisonViewItem?.approved_by_purchase.first_name_last_name }}
                                    <br /><br />ผู้ตรวจสอบ (ฝ่ายจัดซื้อ) <br /><br />วันที่
                                    {{ comparisonViewItem?.approved_by_purchase_date | thaidate : "medium" }}
                                    <br /><br />
                                  </td>
                                  <td *ngIf="comparisonViewItem?.approved_by_purchase_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                    <br />
                                    ลงชื่อ .....................................................
                                    <br /><br />ผู้ตรวจสอบ (ฝ่ายจัดซื้อ) <br /><br />วันที่ ..................................................... <br /><br />
                                  </td>
                                  <br />
                                </div>

                               <div *ngIf="comparisonViewItem?.approved_setting_manager === true" class="col-lg-2">
                              <br />
                                <td *ngIf="comparisonViewItem?.approved_by_manager_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ
                                {{ comparisonViewItem?.manager_approved_name }}
                                <br /><br />ผู้จัดการนิติบุคคลฯ <br /><br />วันที่
                                {{ comparisonViewItem?.approved_by_manager_date | thaidate : "medium" }}
                                <br /><br />
                              </td>
                              <td *ngIf="comparisonViewItem?.approved_by_manager_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ .....................................................
                                <br /><br />ผู้จัดการนิติบุคคลฯ <br /><br />วันที่ ..................................................... <br /><br />
                              </td>
                              <br />
                            </div>
                              </div>
                            </tr>
                            <div *ngIf="checkPage" class="text-end">หน้า 1/2</div>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <!--==== / Print Area =====-->
                </div>
              </div>
            </div>
            <div id="tab2" class="tab-slider--body" *ngSwitchCase="'tab2'">
              <div class="print-area2" id="print-area">
                <div class="row">
                  <div class="col-md-4" *ngIf="comparisonViewItem?.project?.project_logo_image">
                    <img style="margin-top: -30px; margin-left: 10px" [src]="endpointProjectImage + comparisonViewItem?.project?.project_logo_image" width="150px" />
                  </div>
                  <div class="col-md-4">
                    <h4 class="font-weight-bold text-center">
                      {{ comparisonViewItem?.project?.project_name_th }}
                    </h4>
                    <h5 class="font-weight-bold text-center mt-2">ตารางเปรียบเทียบราคา / Price Comparison of : {{ comparisonViewItem?.job_name }}</h5>
                  </div>
                  <div class="col-md-4"></div>

                  <div class="col-md-4">
                    <h6 class="font-weight-bold">รายละเอียด / Detail</h6>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td class="text-left">บริษัทฯ / Supplier / Vendor</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.company_name }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.company_name }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.company_name }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">ชื่อผู้ติดต่อ / Contact Person</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.venderContactPersons }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.venderContactPersons }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.venderContactPersons }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">ที่อยู่ / Address</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.address }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.address }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.address }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">โทรศัพท์ / Telephone</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.telephone }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.telephone }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.telephone }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">รายละเอียดเพิ่มเติม / E-mail</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.email }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.email }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.email }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">เลขที่ผู้เสียภาษี</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.tax_id_number }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.tax_id_number }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.tax_id_number }}</span>
                          </td>
                        </tr>

                        <tr>
                          <th width="290px" class="p-0" scope="col">
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th class="fw-normal text-center" scope="col">
                                    ลำดับ
                                    <br />Item
                                  </th>
                                  <th style="width: 60%" class="fw-normal text-center" scope="col">รายการที่ต้องการ <br />Description</th>
                                  <th class="fw-normal text-center" scope="col">
                                    จำนวน
                                    <br />Quantity
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let masterTemplate of comparisonViewItem?.comparisonMasterTemplate | orderBySortNumberPage : 'number'; let i = index">
                                  <td class="fw-normal text-l" scope="row">
                                    {{ masterTemplate.number }}
                                  </td>
                                  <td class="fw-normal text-left">
                                    {{ masterTemplate.job_type_template_name }}
                                  </td>
                                  <td class="fw-normal text-center">
                                    {{ masterTemplate.quantity }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </th>
                          <th width="500px" class="p-0" scope="col">
                            <table *ngIf="comparisonItemDataPage2[0]" class="table table-bordered">
                              <thead>
                                <tr>
                                  <th style="width: 38%" class="fw-normal text-center" scope="col">
                                    สินค้าที่เสนอ
                                    <br />Products offered
                                  </th>
                                  <th style="width: 10%" class="fw-normal text-center" scope="col">
                                    จำนวน
                                    <br />Quantity
                                  </th>
                                  <th style="width: 18%" class="fw-normal text-center" scope="col">
                                    ราคา/หน่วย
                                    <br />Unit Price
                                  </th>
                                  <th tyle="width: 14%;" class="fw-normal text-center" scope="col">
                                    ส่วนลด
                                    <br />Discount
                                  </th>
                                  <th style="width: 20%" class="fw-normal text-center" scope="col">
                                    ราคารวม
                                    <br />Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let vender1 of comparisonItemDataPage2[0]?.comparisonVenderItem | orderBySortNumberPage : 'number'; let i = index">
                                  <td class="fw-normal text-left">
                                    {{ vender1.job_type_template_name }}
                                  </td>
                                  <td class="fw-normal text-center">
                                    {{ vender1.quantity }}
                                  </td>
                                  <td class="fw-normal text-center" scope="row">
                                    {{ vender1.unit_price }}
                                  </td>
                                  <td class="fw-normal text-center" scope="row">
                                    {{ vender1.discount }}
                                  </td>
                                  <td class="fw-normal text-center" scope="row">
                                    {{ vender1.amount }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </th>
                          <th width="500px" class="p-0" scope="col">
                            <table *ngIf="comparisonItemDataPage2[1]" class="table table-bordered">
                              <thead>
                                <tr>
                                  <th style="width: 38%" class="fw-normal text-center" scope="col">
                                    สินค้าที่เสนอ
                                    <br />Products offered
                                  </th>
                                  <th style="width: 10%" class="fw-normal text-center" scope="col">
                                    จำนวน
                                    <br />Quantity
                                  </th>
                                  <th style="width: 18%" class="fw-normal text-center" scope="col">
                                    ราคา/หน่วย
                                    <br />Unit Price
                                  </th>
                                  <th tyle="width: 14%;" class="fw-normal text-center" scope="col">
                                    ส่วนลด
                                    <br />Discount
                                  </th>
                                  <th style="width: 20%" class="fw-normal text-center" scope="col">
                                    ราคารวม
                                    <br />Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let vender2 of comparisonItemDataPage2[1]?.comparisonVenderItem | orderBySortNumberPage : 'number'; let i = index">
                                  <td class="fw-normal text-left">
                                    {{ vender2.job_type_template_name }}
                                  </td>
                                  <td class="fw-normal text-center">
                                    {{ vender2.quantity }}
                                  </td>
                                  <td class="fw-normal text-center" scope="row">
                                    {{ vender2.unit_price }}
                                  </td>
                                  <td class="fw-normal text-center" scope="row">
                                    {{ vender2.discount }}
                                  </td>
                                  <td class="fw-normal text-center" scope="row">
                                    {{ vender2.amount }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </th>
                          <th width="500px" class="p-0" scope="col">
                            <table *ngIf="comparisonItemDataPage2[2]" class="table table-bordered">
                              <thead>
                                <tr>
                                  <th style="width: 38%" class="fw-normal text-center" scope="col">
                                    สินค้าที่เสนอ
                                    <br />Products offered
                                  </th>
                                  <th style="width: 10%" class="fw-normal text-center" scope="col">
                                    จำนวน
                                    <br />Quantity
                                  </th>
                                  <th style="width: 18%" class="fw-normal text-center" scope="col">
                                    ราคา/หน่วย
                                    <br />Unit Price
                                  </th>
                                  <th tyle="width: 14%;" class="fw-normal text-center" scope="col">
                                    ส่วนลด
                                    <br />Discount
                                  </th>
                                  <th style="width: 20%" class="fw-normal text-center" scope="col">
                                    ราคารวม
                                    <br />Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let vender3 of comparisonItemDataPage2[2]?.comparisonVenderItem | orderBySortNumberPage : 'number'; let i = index">
                                  <td class="fw-normal text-left">
                                    {{ vender3.job_type_template_name }}
                                  </td>
                                  <td class="fw-normal text-center">
                                    {{ vender3.quantity }}
                                  </td>
                                  <td class="fw-normal text-center" scope="row">
                                    {{ vender3.unit_price }}
                                  </td>
                                  <td class="fw-normal text-center" scope="row">
                                    {{ vender3.discount }}
                                  </td>
                                  <td class="fw-normal text-center" scope="row">
                                    {{ vender3.amount }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-center">รวมเงิน / Total</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.total | number : "1.2-2" }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.total | number : "1.2-2" }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.total | number : "1.2-2" }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">ส่วนลด / Discount</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.discount | number : "1.2-2" }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.discount | number : "1.2-2" }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.discount | number : "1.2-2" }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">คงเหลือ / Total</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.total_afterdiscount | number : "1.2-2" }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.total_afterdiscount | number : "1.2-2" }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.total_afterdiscount | number : "1.2-2" }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">ภาษีมูลค่าเพิ่ม / VAT 7%</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.vat | number : "1.2-2" }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.vat | number : "1.2-2" }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.vat | number : "1.2-2" }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">รวมเป็นเงิน/ Grand Total</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.grand_total | number : "1.2-2" }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.grand_total | number : "1.2-2" }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.grand_total | number : "1.2-2" }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">หมายเหตุ</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.remark }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.remark }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.remark }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">เงื่อนไขการชำระเงิน/ Term of Payment</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.term_of_payment }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.term_of_payment }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.term_of_payment }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">การรับประกัน / The werranty</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.warranty }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.warranty }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.warranty }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">ใบเสนอราคายืนราคาถึง</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.quotation_stand }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.quotation_stand }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.quotation_stand }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">ผลงานที่ผ่านมา</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.past_work }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.past_work }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.past_work }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">เงื่อนไขอื่นๆ / Other conditions</td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[0]">{{ comparisonItemDataPage2[0]?.other_condition }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[1]">{{ comparisonItemDataPage2[1]?.other_condition }}</span>
                          </td>
                          <td class="text-center">
                            <span *ngIf="comparisonItemDataPage2[2]">{{ comparisonItemDataPage2[2]?.other_condition }}</span>
                          </td>
                        </tr>
                        <tr class="notPrint">
                          <td class="text-left">ใบเสนอราคา / Quotation</td>
                          <td class="text-left">
                            <div *ngIf="comparisonItemDataPage2[0]" class="row">
                              <div class="col-md-10">
                                <a *ngFor="let file of comparisonItemDataPage2[0].comparisonVenderAttachedFile; let i = index" class="mb-1 col-lg-2" [href]="endpointAttachedFile + file.attached_file_name" target="_blank">
                                  <div>
                                    <img alt="" *ngIf="file.attached_file_type === '.xlsx' || file.attached_file_type === '.xls'" src="/assets/icons/xls.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.pdf'" src="/assets/icons/pdf.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.tyattached_file_typepe === '.doc'" src="/assets/icons/doc.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.jpg'" src="/assets/icons/jpg.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.png'" src="/assets/icons/png.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.ppt' || file.type === '.pptx'" src="/assets/icons/ppt.png" width="10%" class="img-fluid" />&nbsp; &nbsp;
                                    <span> ชื่อไฟล์ : {{ file?.originalname + file.attached_file_type }}</span>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </td>
                          <td class="text-left">
                            <div *ngIf="comparisonItemDataPage2[1]" class="row">
                              <div class="col-md-10">
                                <a *ngFor="let file of comparisonItemDataPage2[1].comparisonVenderAttachedFile; let i = index" class="mb-1 col-lg-2" [href]="endpointAttachedFile + file.attached_file_name" target="_blank">
                                  <div>
                                    <img alt="" *ngIf="file.attached_file_type === '.xlsx' || file.attached_file_type === '.xls'" src="/assets/icons/xls.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.pdf'" src="/assets/icons/pdf.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.tyattached_file_typepe === '.doc'" src="/assets/icons/doc.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.jpg'" src="/assets/icons/jpg.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.png'" src="/assets/icons/png.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.ppt' || file.type === '.pptx'" src="/assets/icons/ppt.png" width="10%" class="img-fluid" />&nbsp; &nbsp;
                                    <span> ชื่อไฟล์ : {{ file?.originalname + file.attached_file_type }}</span>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </td>
                          <td class="text-left">
                            <div *ngIf="comparisonItemDataPage2[2]" class="row">
                              <div class="col-md-10">
                                <a *ngFor="let file of comparisonItemDataPage2[2].comparisonVenderAttachedFile; let i = index" class="mb-1 col-lg-2" [href]="endpointAttachedFile + file.attached_file_name" target="_blank">
                                  <div>
                                    <img alt="" *ngIf="file.attached_file_type === '.xlsx' || file.attached_file_type === '.xls'" src="/assets/icons/xls.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.pdf'" src="/assets/icons/pdf.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.tyattached_file_typepe === '.doc'" src="/assets/icons/doc.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.jpg'" src="/assets/icons/jpg.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.png'" src="/assets/icons/png.png" width="10%" class="img-fluid" />
                                    <img alt="" *ngIf="file.attached_file_type === '.ppt' || file.type === '.pptx'" src="/assets/icons/ppt.png" width="10%" class="img-fluid" />&nbsp; &nbsp;
                                    <span> ชื่อไฟล์ : {{ file?.originalname + file.attached_file_type }}</span>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h6>
                      <span>หมายเหตุ : แนะนำให้เลือก : </span> <span> {{ comparisonViewItem?.recommended }}</span
                      >&nbsp; &nbsp;&nbsp; &nbsp; เนื่องจาก :
                      <span> {{ comparisonViewItem?.reason }}</span>
                    </h6>
                    <h6 style="margin-left: 40px">* รายละเอียดดังกล่าวให้คณะกรรมการพิจารณาอีกครั้ง</h6>
                    <br />
                    <table class="table table">
                      <tbody>
                        <tr>
                          <div class="row d-flex justify-content-end">
                            <div class="col-lg-2">
                              <br />
                              <td *ngIf="comparisonViewItem?.approved_by_bm_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ
                                {{ comparisonViewItem?.approved_by_bm?.first_name_last_name }}
                                <br /><br />ผู้จัดทำ / ผู้นำเสนอ <br /><br />วันที่
                                {{ comparisonViewItem?.approved_by_bm_date | thaidate : "medium" }}

                                <br /><br />
                              </td>
                              <td *ngIf="comparisonViewItem?.approved_by_bm_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ .....................................................
                                <br /><br />ผู้จัดทำ / ผู้นำเสนอ <br /><br />วันที่ ..................................................... <br /><br />
                              </td>
                              <br />
                            </div>
                            <div *ngIf="comparisonViewItem?.verification_by_engineer" class="col-lg-2">
                              <br />
                              <td *ngIf="comparisonViewItem?.approved_by_chief_engineer_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ
                                {{ comparisonViewItem?.approved_by_chief_engineer?.first_name_last_name }}
                                <br /><br />หัวหน้าช่าง <br /><br />วันที่
                                {{ comparisonViewItem?.approved_by_bm_date | thaidate : "medium" }}
                                <br /><br />
                              </td>
                              <td *ngIf="comparisonViewItem?.approved_by_chief_engineer_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ .....................................................
                                <br /><br />หัวหน้าช่าง <br /><br />วันที่ ..................................................... <br /><br />
                              </td>
                              <br />
                            </div>
                            <div *ngIf="comparisonViewItem?.verification_by_engineer" class="col-lg-2">
                              <br />
                              <td *ngIf="comparisonViewItem?.approved_by_central_engineering_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ
                                {{ comparisonViewItem?.approved_by_central_engineering?.first_name_last_name }}
                                <br /><br />วิศวกรรมส่วนกลาง <br /><br />วันที่
                                {{ comparisonViewItem?.approved_by_central_engineering_date | thaidate : "medium" }}
                                <br /><br />
                              </td>
                              <td *ngIf="comparisonViewItem?.approved_by_central_engineering_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ .....................................................
                                <br /><br />วิศวกรรมส่วนกลาง <br /><br />วันที่ ..................................................... <br /><br />
                              </td>
                              <br />
                            </div>

                            <div class="col-lg-2">
                              <br />
                              <td *ngIf="comparisonViewItem?.approved_by_om_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ
                                {{ comparisonViewItem?.approved_by_om.first_name_last_name }}
                                <br /><br />ผู้จัดการฝ่ายปฏิบัติการ <br /><br />วันที่
                                {{ comparisonViewItem?.approved_by_om_date | thaidate : "medium" }}
                                <br /><br />
                              </td>
                              <td *ngIf="comparisonViewItem?.approved_by_om_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ .....................................................
                                <br /><br />ผู้จัดการฝ่ายปฏิบัติการ <br /><br />วันที่ ..................................................... <br /><br />
                              </td>
                              <br />
                            </div>
                            <div class="col-lg-2">
                              <br />
                              <td *ngIf="comparisonViewItem?.approved_by_purchase_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ
                                {{ comparisonViewItem?.approved_by_purchase.first_name_last_name }}
                                <br /><br />ผู้ตรวจสอบ (ฝ่ายจัดซื้อ) <br /><br />วันที่
                                {{ comparisonViewItem?.approved_by_purchase_date | thaidate : "medium" }}
                                <br /><br />
                              </td>
                              <td *ngIf="comparisonViewItem?.approved_by_purchase_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ .....................................................
                                <br /><br />ผู้ตรวจสอบ (ฝ่ายจัดซื้อ) <br /><br />วันที่ ..................................................... <br /><br />
                              </td>
                              <br />
                            </div>

                            <div *ngIf="comparisonViewItem?.approved_setting_manager === true" class="col-lg-2">
                              <br />
                                <td *ngIf="comparisonViewItem?.approved_by_manager_status === true" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ
                                {{ comparisonViewItem?.manager_approved_name }}
                                <br /><br />ผู้จัดการนิติบุคคลฯ <br /><br />วันที่
                                {{ comparisonViewItem?.approved_by_manager_date | thaidate : "medium" }}
                                <br /><br />
                              </td>
                              <td *ngIf="comparisonViewItem?.approved_by_manager_status === false" style="background-color: #dae3f3" class="text-center d-flex justify-content-center">
                                <br />
                                ลงชื่อ .....................................................
                                <br /><br />ผู้จัดการนิติบุคคลฯ <br /><br />วันที่ ..................................................... <br /><br />
                              </td>
                              <br />
                            </div>
                          </div>
                        </tr>
                        <div *ngIf="checkPage" class="text-end">หน้า 2/2</div>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!--==== / Print Area =====-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalConfirmRequest let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">ส่งขออนุมัติรายการ</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      <strong>คุณแน่ใจที่จะส่งขออนุมัติรายการ<span class="text-primary"></span> ?</strong>
    </p>
    <p>
      คลิก ส่งขออนุมัติรายการ เพื่อส่งขออนุมัติรายการตารางเปรียบเทียบ หลังจากส่งขออนุมัติรายการตารางเปรียบเทียบ จะไม่สามารถกลับมาแก้ไขรายการได้.
      <span class="text-danger">คลิก ยกเลิก เพื่อกลับไปเปลี่ยนแปลงข้อมูล.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-warning btn-rounded" (click)="modal.close('cancel')">ยกเลิก</button>
    <button type="button" ngbAutofocus class="btn btn-success btn-rounded" (click)="modal.close('Ok')">ส่งขออนุมัติรายการ</button>
  </div>
</ng-template>
<ng-template #modalConfirmApproved let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">อนุมัติรายการ</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      <strong>คุณแน่ใจทีจะอนุมัติรายการ : {{ comparisonViewItem?.job_name }}<span class="text-primary"></span> ?</strong>
    </p>
    <p>
      คลิก อนุมัติรายการ เพื่อส่งขออนุมัติรายการตารางเปรียบเทียบ
      <span class="text-danger">คลิก ยกเลิก เพื่อกลับไปเปลี่ยนแปลงข้อมูล.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-warning btn-rounded" (click)="modal.close('cancel')">ยกเลิก</button>
    <button type="button" ngbAutofocus class="btn btn-success btn-rounded" (click)="modal.close('Ok')">อนุมัติรายการ</button>
  </div>
</ng-template>

<ng-template #modalConfirmNotApproved let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">ไม่อนุมัติรายการ</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="followupItemDataForm" id="-update">
      <div class="row">
        <div class="col-md-12 form-group">
          <label class="mb-2" for="remark">เหตุผลในการไม่อนุมัติรายการ</label>
          <textarea formControlName="remark" placeholder="ใส่ข้อมูลเหตุผลตรงนี้..." id="picker1" cols="12" rows="5" class="form-control"></textarea> <br />
          <span *ngIf="clickSubmit === true && !followupItemDataForm.value.remark" class="warning">
            <h6 class="text-danger">โปรดใส่เหตุผลในการขอลบข้อมูลตรงนี้!</h6>
          </span>
        </div>
      </div>
    </form>
    <p>
      <strong>คุณแน่ใจทีจะไม่อนุมัติรายการ : {{ comparisonViewItem?.job_name }}<span class="text-primary"> นี้</span> ?</strong>
    </p>
    <p>
      คลิก อนุมัติรายการ เพื่อส่งขออนุมัติรายการตารางเปรียบเทียบ
      <span class="text-danger">คลิก ยกเลิก เพื่อกลับไปเปลี่ยนแปลงข้อมูล.</span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-warning btn-rounded" (click)="modal.close('cancel')">ยกเลิก</button>
    <button type="button" ngbAutofocus class="btn btn-danger btn-rounded" (click)="modal.close('Ok')">ไม่อนุมัติรายการ</button>
  </div>
</ng-template>

<ng-template #modalStatus let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">สถานะรายการ</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p-scrollPanel [style]="{ width: '100%', height: '500px' }" styleClass="custombar1">
      <div class="row">
        <div class="col-lg-2 text-center"></div>
        <div class="col-lg-8 text-center">
          <div id="DZ_W_TimeLine" class="widget-timeline dz-scroll height370" perfectScrollbar>
            <ul class="timeline">
              <li *ngFor="let item of comparisonViewItem?.comparisonLog | sortStatusBydate : 'updated_at'">
                <div class="timeline-badge" [ngClass]="{ danger: item?.status === 'ไม่อนุมัติรายการ', warning: item?.status === 'บันทึกแบบร่าง' || item.status === 'ขออนุมัติ', info: item.status === 'อนุมัติโดยผู้จัดการอาคาร' || item.status === 'อนุมัติโดยหัวหน้าช่าง' || item.status === 'อนุมัติโดยวิศวกรรมส่วนกลาง' || item.status === 'อนุมัติโดยผู้จัดการฝ่ายปฏิบัติการ' || item.status === 'อนุมัติโดยผู้ตรวจสอบ (ฝ่ายจัดซื้อ)', success: item.status === 'เสร็จสิ้น' }"></div>

                <a class="timeline-panel text-muted">
                  <div class="row">
                    <span class="text-start font-w900 text-info col-lg-6">วันที่ {{ item.updated_at | date : "dd/MM/yyy HH:mm" }} </span>
                  </div>
                  <h6 *ngIf="item.manager_approved_name" class="mb-0 text-start">คุณ {{ item.manager_approved_name }} ได้ทำรายการ {{ item.status }}</h6>
                   <h6 *ngIf="!item.manager_approved_name"class="mb-0 text-start">คุณ {{ item.user_created_log.first_name_last_name }} ได้ทำรายการ {{ item.status }}</h6>
                  <br />
                  <h6 *ngIf="item.remark" class="mb-0 text-start">หมายเหตุ : {{ item.remark }}</h6>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 text-center"></div>
      </div>
    </p-scrollPanel>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-warning btn-rounded" (click)="modal.close('cancel')">ปิด</button>
  </div>
</ng-template>

