<div style="text-align: center;" class=" m-2 mt-5 text-center">
    <img src="./assets/images/logo.png" width="150px" alt="">
    <ul>


    </ul>
</div>
<div class=" m-2 text-center">
    <h1>เเบบขอใช้บริการผู้ใช้งานในระบบสารสนเทศ</h1>
    <ul>


    </ul>
</div>
<div class="separator-breadcrumb border-top"></div>
<div class="row m-4">
    <div class="col-md-12 ">
        <div class="card mb-4 ">
            <div class="card-body">
                <div class="row">
                    <div class="text-start col-lg-10 col-md-6 col-sm-6">
                        <div class="card-title mb-3">ส่วนที่ 1 สำหรับผู้ขอใช้บริการ</div>
                    </div>
                    <div class="text-end col-lg-2 col-md-6 col-sm-6">

                    </div>
                </div>

                <form>
                    <div class="row">
                        <div class="col-md-1 form-group mb-3">
                            <label for="firstName">คำนำหน้าชื่อ<span class="text-info">*</span></label>
                            <select class="form-control minimal" data-id="0" data-key="transaction_list"
                                placeholder=" cccc" name="whtRatePercent">
                                <option class="boldoption" value='Complete'>นาย</option>
                                <option class="boldoption" value='Process'>นาง</option>
                                <option class="boldoption" value='Waiting'>นางสาว</option>
                            </select>

                        </div>
                        <div class="col-md-2 form-group mb-3">
                            <label for="firstName">ชื่อ<span class="text-info">*</span></label>
                            <input class="form-control bg-white">

                        </div>
                        <div class="col-md-2 form-group mb-3">
                            <label for="firstName">นามสกูล<span class="text-info">*</span></label>
                            <input class="form-control bg-white">

                        </div>

                        <div class="col-md-2 form-group mb-3">
                            <label for="firstName">FirstName<span class="text-info">*</span></label>
                            <input class="form-control bg-white">

                        </div>

                        <div class="col-md-2 form-group mb-3">
                            <label for="firstName">Lastname<span class="text-info">*</span></label>
                            <input class="form-control bg-white">

                        </div>
                        <div class="col-md-1 form-group mb-3">
                            <label for="firstName">หมายเลขโทรศัพท์<span class="text-info">*</span></label>
                            <input class="form-control bg-white">

                        </div>
                        <div class="col-md-2 form-group mb-3">
                            <label for="firstName">อีเมล์<span class="text-info">*</span></label>
                            <input class="form-control bg-white">

                        </div>
                        <div class="col-md-4 form-group mb-3">
                            <label for="firstName">โครงการ<span class="text-info">*</span></label>
                                 <ng-multiselect-dropdown formControlName="project"
                                [ngStyle]="{'border-top': '300px solid lightgreen'}" [placeholder]="'โปรดเลือกโครงการ'"
                                [settings]="dropdownSettingsProject" [data]="projectList" id="project">
                            </ng-multiselect-dropdown>

                        </div>
                        <div class="row">
                            <div class="text-start col-lg-10 col-md-6 col-sm-6">
                                <div class="card-title mb-3">ความประสงค์ขอใช้ระบบสารสนเทศ ดังนี้</div>
                            </div>
                            <div class="text-end col-lg-2 col-md-6 col-sm-6">

                            </div>
                        </div>
                        <div class="col-md-2 form-group mb-3">
                            <label class="checkbox checkbox-outline-primary mt-3">
                                <input type="checkbox">
                                <span class="text-info">เปิดใช้งานผู้ใช้งานใหม่</span>
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="col-md-2 form-group mb-3">
                            <label class="checkbox checkbox-outline-primary mt-3">
                                <input type="checkbox">
                                <span class="text-info">ขอ User/Password</span>
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="col-md-2 form-group mb-3">
                            <label class="checkbox checkbox-outline-primary mt-3">
                                <input type="checkbox">
                                <span class="text-info">ขอเปลี่ยนข้อมูลการใช้งาน</span>
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="row">
                            <div class="text-start col-lg-10 col-md-6 col-sm-6">
                                <div class="card-title mb-3">ระบบที่ใช้ในการปฎิบัตรงาน</div>
                            </div>
                            <div class="text-end col-lg-2 col-md-6 col-sm-6">

                            </div>
                        </div>
                        <div class="col-md-3 form-group mb-3">
                            <label class="checkbox checkbox-outline-primary mt-3">
                                <input type="checkbox">
                                <span class="text-info">ระบบบริหารงานนิติบุคคล PMS.(Property Management System)</span>
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="col-md-3 form-group mb-3">
                            <label class="checkbox checkbox-outline-primary mt-3">
                                <input type="checkbox">
                                <span class="text-info">ระบบบริการจัดการบริการ VMS.(Victory Management System)</span>
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="col-md-7 form-group mb-3">
                            <label for="email_companydd">หมายเหตุ<span class="text-info"></span></label>
                            <textarea id="first_name111" rows="3" class="form-control" type="text"></textarea>

                        </div>
                        <br><br>
                        <div class="col-md-8 form-group mb-3 mt-3">
                            <label for="firstName ">*ข้าพเจ้าขอรับรองว่าข้อมูลข้างต้นเป็นความจริง และรับทราบ เกี่ยวกับ
                                พรบ.คุ้มครองข้อมูลส่วนบุคคล,นโยบายการรักษาความลับของบริษัทฯ
                                และไม่ส่งออกข้อมูลส่วนหนึ่งส่วนใดของข้อมูล,โครงสร้างระบบการทางานอื่นใดที่เกี่ยวข้องในระบบเทคโนโลยี
                                สารสนเทศของบริษัทฯ หรือ การกระทำที่เกี่ยวข้องกับกฎหมายและจริยธรรมทางเทคโนโลยีสารสนเทศ
                                ทุกประการ</label>
                        </div>


                    </div>
                    <div class="col-sm-12 text-end ">
                        <button type="button" class="btn btn-warning">ล้างข้อมูล</button> &nbsp; &nbsp;
                        <button type="button" class="btn btn-success ">ส่งขอใช้บริการ</button>
                    </div>

                </form>
            </div>
        </div>
    </div>

    <!-- 
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-body">
                <div class="row">
                    <div class="text-start col-lg-9 col-md-6 col-sm-6">
                        <div class="card-title mb-3">ข้อมูล Follow up</div>
                    </div>
                    <div class="text-end col-lg-3 col-md-6 col-sm-6">
                        <button style="margin-left: 20px;"
                            
                            class="btn btn-secondary ">อัพเดตข้อมูล</button>
                    </div>
                </div> <br>

               
            </div>
        </div>
    </div> -->