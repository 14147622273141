import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComparisonVenderService extends RestService {
  endpoint = `${environment.api}/comparison-vender`;
  comparisonItemData: any



  createComparisonVender(comparison_id: any, data: any): Observable<any> {
    return this.http.post(`${environment.api}/comparison-vender/create/${comparison_id}`, data);
  }

  editComparisonVender(comparison_vender_id: any, data: any): Observable<any> {
    return this.http.put(`${environment.api}/comparison-vender/edit/${comparison_vender_id}`, data);
  }

 editComparisonVenderNumber(comparison_vender_id: any, data: any): Observable<any> {
    return this.http.put(`${environment.api}/comparison-vender/change-number-comapny/${comparison_vender_id}`, data);
  }





}
