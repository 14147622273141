import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class VenderListService {
  venderItemData: any
   endpointAttachedFile = `${environment.api}/vender-attached-file/file/`;
  constructor(protected http: HttpClient) { }
  sendDataViewDataItem(venderItemData: any) {
    this.venderItemData = venderItemData
  }
  reciveDataViewDataItem() {

    return this.venderItemData
  }
  get(page: any, filterData: any): Observable<any> {
    return this.http.post<any>(`${environment.api}/vender/list?page=${page}`, filterData);
  }
  getWaitingVerification(page: any, filterData: any): Observable<any> {
    return this.http.post<any>(`${environment.api}/vender/list-waiting-verification?page=${page}`, filterData);
  }


  getUserList(): Observable<any> {
    return this.http.get<any>(`${environment.api}/users/userlist`);
  }
  create(data: any): Observable<any> {
    return this.http.post(`${environment.api}/vender/create`, data);
  }
  createByUser(data: any): Observable<any> {
    return this.http.post(`${environment.api}/vender/create-user`, data);
  }

 edit(vender_id:any,data: any): Observable<any> {
    return this.http.put(`${environment.api}/vender/${vender_id}`, data);
  }

 approved(vender_id:any,data: any): Observable<any> {
    return this.http.put(`${environment.api}/vender/approved/${vender_id}`, data);
  }


  uploadVenderAttacheFile(vederId: string, data: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', data);
    return this.http.post<any>(`${environment.api}/vender-attached-file/file/${vederId}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

  }
 deleteReqest(data_request: any): Observable<any> {
    return this.http.post(`${environment.api}/vender-deletion-request`, data_request);
  }
getRequestdelete(venderListID: any): Observable<any> {
    return this.http.get<any>(`${environment.api}/vender-deletion-request/${venderListID}`);
  }
notApproved(vender_id:any,data: any): Observable<any> {
    return this.http.put(`${environment.api}/vender/notapproved/${vender_id}`, data);
  }
}
