import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGaurd } from "./shared/services/auth.gaurd";
import { BlankLayoutComponent } from "./shared/components/layouts/blank-layout/blank-layout.component";
import { AdminLayoutSidebarCompactComponent } from "./shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component";
import { AdminLayoutSidebarLargeComponent } from "./shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component";
import { UserProfileComponent } from "./views/pages/user-profile/user-profile.component";
import { RequestFormCreateGuestComponent } from "./views/request-form-create-guest/request-form-create-guest.component";
import { LineActivatedComponent } from "./views/line-service/line-activated/line-activated.component";
import { ComparisonViewComponent } from "./views/comparison/comparison-view/comparison-view.component";
import { ComparisonViewOutsiteComponent } from "./views/comparison/comparison-view-outsite/comparison-view-outsite.component";

const date = new Date();
const adminRoutes: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("./views/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "settings",
    loadChildren: () => import("./views/settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "followlist",
    loadChildren: () => import("./views/followlist/followlist.module").then((m) => m.FollowlistModule),
  },
  {
    path: "inventory",
    loadChildren: () => import("./views/inventory/inventory.module").then((m) => m.InventoryModule),
  },
  {
    path: "user",
    loadChildren: () => import("./views/pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "vender",
    loadChildren: () => import("./views/vender/vender.module").then((m) => m.VenderModule),
  },
  {
    path: "comparison",
    loadChildren: () => import("./views/comparison/comparison.module").then((m) => m.ComparisonModule),
  },
];

const routes: Routes = [
  {
    path: "",
    redirectTo: "followlist",
    pathMatch: "full",
  },
  {
    path: "request-from-create",
    component: RequestFormCreateGuestComponent,
  },
  {
    path: "id/:comparison_id",
    component: ComparisonViewOutsiteComponent,
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: () => import("./views/sessions/sessions.module").then((m) => m.SessionsModule),
      },
    ],
  },
  {
    path: "",
    component: LineActivatedComponent,
    children: [
      {
        path: "line/activate/795d2943-25e7-11ee-9cb8-f02f74a20364",
        loadChildren: () => import("./views/line-service/line-service.module").then((m) => m.LineServiceModule),
      },
    ],
  },
  {
    path: "",
    component: BlankLayoutComponent,
    children: [
      {
        path: "others",
        loadChildren: () => import("./views/others/others.module").then((m) => m.OthersModule),
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes,
  },
  {
    path: "**",
    redirectTo: "others/404",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
