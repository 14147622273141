import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface IMenuItem {
  id?: string;
  title?: string;
  description?: string;
  type: string; // Possible values: link/dropDown/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  active?: boolean;
}
export interface IChildItem {
  id?: string;
  parentId?: string;
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false,
  };
  selectedItem: IMenuItem;

  constructor() {}

  defaultMenu: IMenuItem[] = [
    /*  {
            name: 'แดชบอร์ด',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Bar-Chart',

        }, */
    /*
               {
                   name: 'ข่าวสารองค์กร',
                   description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                   type: 'dropDown',
                   icon: 'i-File-Clipboard-File--Text',
                   sub: [
       
                   ]
               },
               {
                   name: 'รายละเอียดโครงการ',
                   description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                   type: 'dropDown',
                   icon: 'i-File-Horizontal-Text',
                   sub: [
       
                   ]
               },  */
    {
      name: "งานที่ได้รับมอบหมาย",
      description: "งานที่ได้รับมอบหมาย",
      type: "link",
      icon: "i-Administrator",
      state: "/followlist",
    },
    {
      name: "VENDER LIST",
      description: "VENDER LIST",
      type: "link",
      icon: "i-Folder-Download",
      state: "/vender",
    },
    {
      name: "ตารางเปรียบเทียบ",
      description: "600+ premium icons",
      type: "link",
      icon: "i-Library",
      state: "/comparison",
    },
    /*  {
            name: 'การจัดการสต็อก',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                { name: 'การจัดการสต็อคและวัสดุสิ้นเปลือง', state: '/inventory', type: 'link' },
                  {  name: 'รายงาน', state: '/inventory/report', type: 'link' },  
                 { name: 'Daily Report (HO)', state: '/followlist/v1', type: 'link' },  
                 { name: 'Weekly Report', state: '/followlist/v1', type: 'link' },  
                 {  name: 'Monthly Report', state: '/followlist/v1', type: 'link' },  
                 { name: 'รายงานสรุป Follow Up list', state: '/followlist/v1', type: 'link' }, 
                 {  name: 'รายงานการเดินตรวจความสมบูรณ์โครงการ', state: '/followlist/v1', type: 'link' },
                 {  name: 'รายงานสรุป เดินตรวจอาคาร', state: '/followlist/v1', type: 'link' }, 
            ]


        }, */
    /*     {
                name: 'แจ้งซ่อม',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
                type: 'dropDown',
                icon: 'i-Library',
                sub: [
    
    
                ]
            },
            {
                name: 'แจ้งเรื่องร้องเรียน',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'dropDown',
                icon: 'i-Computer-Secure',
                sub: [
    
                ]
            },
            {
                name: 'เอกสารแต่ละหน่วยงาน',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'dropDown',
                icon: 'i-File-Word',
                sub: [
    
                ]
            },
            {
                name: 'Download เอกสาร',
                description: '600+ premium icons',
                type: 'link',
                icon: 'i-Folder-Download',
                state: '/icons/iconsmind'
            },
            
            {
                name: 'รายงาน',
                type: 'extLink',
                tooltip: 'Documentation',
                icon: 'i-Align-Left',
                state: 'https://vms-systems.com/'
            },
            {
                name: 'งานจัดซื้อ จัดจ้าง',
                type: 'extLink',
                tooltip: 'Documentation',
                icon: 'i-Receipt',
                state: 'https://vms-systems.com/'
            },
            {
                name: 'VOD Training และคู่มือ',
                type: 'extLink',
                tooltip: 'Documentation',
                icon: 'i-Video-2',
                state: 'https://vms-systems.com/'
            }, */
  ];

  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // You can customize this method to supply different menu for
  // different user type.
  // publishNavigationChange(menuType: string) {
  //   switch (userType) {
  //     case 'admin':
  //       this.menuItems.next(this.adminMenu);
  //       break;
  //     case 'user':
  //       this.menuItems.next(this.userMenu);
  //       break;
  //     default:
  //       this.menuItems.next(this.defaultMenu);
  //   }
  // }
}
